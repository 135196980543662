import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './dashboard';
import HeaderMenu from './header-menu';
import Hero from './hero';
import AndroidCodeBackground from './mock-code';
import Chatbot from './chat';
import Recovery from './recovery';
import Dream from './your-dream';
import Footer from './footer';
import Popup from './popup';
import Stores from './custom-stores';
import FinalRecovery from './final-recovery';
import Robotics from './ai.js';
import FireballCursor from './FireballCursor';
import SocialMediaBar from './sm';
import PopupLogin from './login.js';
import Leads from './leads.js';
import PricingComponent from './pricing.js';
import Portfolio from './portfolio.js';
import Agreement from './agreement.js';
import WebDevPopup from './webpayment.js';
import SocialMediaPopup from './socialMediaManagement.js';
const Home = () => (
    <div>
        <FireballCursor />
        <PopupLogin />
        <Leads />
        <SocialMediaBar />
        <Hero />
        <AndroidCodeBackground />
        <Dashboard />
        <Stores />
        {/* <Chatbot /> */}
        <Recovery />
        <Dream />
        <PricingComponent />
        <FinalRecovery />
        <Robotics />
        <Portfolio />
        <Agreement />
        <WebDevPopup />
        <SocialMediaPopup />
    </div>
);
const About = () => (
  <div>
      <Hero />
      <Chatbot />
      <Recovery />
  </div>
);
const Services = () => (
  <div>
      <Hero />
      <AndroidCodeBackground />
      <Dashboard />
      <Chatbot />
      <Recovery />
  </div>
);
const Contact = () => (
  <div>
      <Hero />
      <Chatbot />
      <Recovery />
  </div>
);

function App() {
    return (
        <Router>
            <div className="App">
                <HeaderMenu />
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/services" element={<Services/>} />
                    <Route path="/contact" element={<Contact/>} />
                </Routes>
                <Popup />
                <Footer />
            </div>
        </Router>
    );
}

export default App;
