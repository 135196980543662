import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const Robotics = () => {
  const videoRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const element = videoRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (videoRef.current && inView) {
        const scale = .8 + currentPosition * 0.00015;
        videoRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView]);

  return (
    <Box
      sx={{
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
        color: '#212121',
        fontFamily: 'monospace',
        overflow: 'hidden',
        position: 'relative',
        padding: '40px'
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
          opacity: 0.1,
          transition: 'transform 0.2s ease-in-out'
        }}
      >
        <source src="/robotics.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box
        sx={{
          backgroundColor: 'transparent',
          width: '100%',
          textAlign: 'left'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            paddingLeft: '40px',
            fontSize: '1.0rem',
            color: '#29B6F6!important',
            marginTop: '80px'
          }}
        >
          The Bottom Line
          <Typography
            sx={{
              color: '#FFB300',
              fontSize: '48px',
              lineHeight: '48px'
            }}
          >
            We're leveling The Playing Field.
          </Typography>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            padding: { xs: '10px', sm: '10px', md: '20px', lg: '10px 40px' },
            fontSize: '1.0rem',
            color: '#212121',
            marginTop: '20px'
          }}
        >
          Large Companies Use The Latest In Machine Learning/Artificial Technology (AI) And Evolving Marketing Methodologies. Lightweight exists to provide small businesses with solutions in two important areas. 
        </Typography>
        <Typography
          variant="h5"
          sx={{
            padding: { xs: '10px', sm: '10px', md: '20px', lg: '10px 40px' },
            fontSize: '1.0rem',
            color: '#212121',
            marginTop: '20px'
          }}
        >
          1. Technologically - utilizing ML (Machine Learning optimizing team resource management for example), A.I, Modern Web, Optimized Email, Managed Social, Useful Analytics, And Custom Apps To Manage Your Unique Business Needs.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            padding: { xs: '10px', sm: '10px', md: '20px', lg: '10px 40px' },
            fontSize: '1.0rem',
            color: '#212121',
            marginTop: '20px'
          }}
        >
          2. Strategically - You Will Always Be Guided By The Most Up-To-Date Marketing Strategies And Using A.I. Assisted Project Management Tools To Streamline Operations. We Exist To Support Small Businesses By Keeping Them Competitive.
        </Typography>
      </Box> 
    </Box>
  );
};

export default Robotics;
