import React, { useEffect, useState } from 'react';

const SocialMediaPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Shift + Alt + S is pressed
      if (event.shiftKey && event.altKey && event.code === 'KeyS') {
        event.preventDefault(); // Prevent default browser behavior
        setShowPopup(true); // Show the popup
      }
    };

    // Add event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <h2>Social Media Management Service</h2>
            <p>
              Take your social media presence to the next level! Our service includes:
            </p>
            <ul style={styles.list}>
              <li>✔️ Professionally crafted posts tailored to your brand</li>
              <li>✔️ Daily audience engagement to build relationships</li>
              <li>✔️ Insights and analytics to measure growth</li>
              <li>✔️ Expert strategies to grow your following</li>
            </ul>
            <p>
              <strong>Price:</strong> $100/month + taxes
            </p>
            <a
              href="https://buy.stripe.com/bIYbJtehUex78bm8wD"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.link}
            >
              Sign Up Now
            </a>
            <button onClick={handleClosePopup} style={styles.closeButton}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: '#EEEEEE',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '90%',
  },
  list: {
    textAlign: 'left',
    margin: '10px 0',
    padding: 0,
    listStyleType: 'none',
    lineHeight: '1.5',
  },
  link: {
    display: 'inline-block',
    marginTop: '10px',
    padding: '10px 20px',
    color: '#FFB300',
    textDecoration: 'none',
    borderRadius: '4px',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default SocialMediaPopup;
