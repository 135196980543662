import React, { useEffect } from 'react';

const Agreement = () => {
  // Path to the PDF file
  const pdfUrl = '/lightweight-agreement.pdf'; // Replace with your PDF file's URL

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Shift + Alt + A is pressed
      if (event.shiftKey && event.altKey && event.code === 'KeyA') {
        event.preventDefault(); // Prevent default browser behavior
        // Create an anchor element to download the PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'lightweight-agreement.pdf'; // Set the download filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    // Add event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [pdfUrl]);

  return (
    <div>
    </div>
  );
};

export default Agreement;