import React from 'react';
import { Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';

const SocialMediaBar = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '3px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        zIndex: 1000,
      }}
    >
      <IconButton href="https://www.facebook.com/lightweight.agency/" target="_blank" rel="noopener">
        <FacebookIcon sx={{ color: '#FFB300', fontSize: '1.5rem' }} />
      </IconButton>
      <IconButton href="https://www.instagram.com/lightweight.agency/" target="_blank" rel="noopener">
        <InstagramIcon sx={{ color: '#FFB300', fontSize: '1.5rem' }} />
      </IconButton>
      <IconButton href="https://t.me/lightweight_agency" target="_blank" rel="noopener">
        <TelegramIcon sx={{ color: '#FFB300', fontSize: '1.5rem' }} />
      </IconButton>
    </Box>
  );
};

export default SocialMediaBar;
