import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, MenuItem, Fab, Tooltip } from '@mui/material';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './popup.css';

// Function to sanitize input by removing any HTML/JS tags
const sanitizeInput = (input) => {
    if (typeof input === 'string') {
        // Remove all HTML and script tags
        return input.replace(/<\/?[^>]+(>|$)/g, "").replace(/<script.*?>.*?<\/script>/gi, '');
    }
    return input;
};

const Popup = () => {
    const [open, setOpen] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        intent: '',
    });

    useEffect(() => {
        const handleScroll = () => {
            if (!timerStarted) {
                setTimerStarted(true);
                const timer = setTimeout(() => {
                    setOpen(true);
                }, 20000); // 20 seconds

                return () => clearTimeout(timer);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [timerStarted]);

    useEffect(() => {
        const tooltipTimer = setTimeout(() => {
            setTooltipOpen(true);
        }, 5000); // 5 seconds

        return () => clearTimeout(tooltipTimer);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Sanitize input fields to prevent XSS or script injection
        const sanitizedFullName = sanitizeInput(formData.fullName);
        const sanitizedEmail = sanitizeInput(formData.email);
        const sanitizedIntent = sanitizeInput(formData.intent);

        const timestamp = new Date().toLocaleString();
        const dataWithTimestamp = {
            fullName: sanitizedFullName,
            email: sanitizedEmail,
            intent: sanitizedIntent,
            timestamp
        };

        try {
            const docRef = await addDoc(collection(db, "submissions"), dataWithTimestamp);

            // Show a customized toast notification
            toast.success("Form submitted successfully!", {
                style: {
                  backgroundColor: '#FFB300',  // Background color of the toast
                  color: '#fff',                // Text color
                },
                progressStyle: {
                  backgroundColor: '#4FC3F7',   // Progress bar color
                },
                hideProgressBar: false,           // Ensures progress bar is visible
                position: "top-right",            // Position of the toast
                autoClose: 5000,                  // Auto-close after 5 seconds
                draggable: true,                  // Allow dragging the toast
                className: 'custom-toast-success', // Add a custom class to target the icon
              });              

            setFormData({
                fullName: '',
                email: '',
                intent: '',
            });

            handleClose();
        } catch (error) {
            console.error("Error adding document: ", error);
            toast.error("Error submitting form. Please try again.", {
                style: { backgroundColor: '#f44336', color: '#fff' },
            });
        }
    };

    const handleFabClick = () => {
        setOpen(true);
        setTooltipOpen(false);
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {!open && (
                <Tooltip title="Contact Us" sx={{ fontSize: '18px' }}>
                    <Fab
                        color="primary"
                        aria-label="message"
                        onClick={handleFabClick}
                        sx={{
                            position: 'fixed',
                            bottom: 48,
                            right: 48,
                            backgroundColor: '#29B6F6',
                            '&:hover': {
                                backgroundColor: '#0288D1',
                            },
                        }}
                    >
                        <MessageIcon sx={{ color: '#FFB300' }} />
                    </Fab>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { boxShadow: 24, borderRadius: 2, minWidth: '350px', margin: 'auto' } }}>
                <DialogTitle>
                    <Typography className='brand-font primary'>Welcome to lightweight</Typography>
                    <Typography>How Can We Best Serve You</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#616161',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#29B6F6',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#29B6F6',
                                    },
                                },
                            }}
                        />
                        <TextField
                            label="Email Address"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#616161',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#29B6F6',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#29B6F6',
                                    },
                                },
                            }}
                        />
                        <TextField
                            label="I need..."
                            name="intent"
                            select
                            value={formData.intent}
                            onChange={handleChange}
                            required
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#616161',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#29B6F6',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#29B6F6',
                                    },
                                },
                            }}
                        >
                            <MenuItem value="We Need A Freelance Consultant">We Need A Freelance Consultant</MenuItem>
                            <MenuItem value="I Want To Talk About Business">I Want To Talk About Business</MenuItem>
                            <MenuItem value="We Need Creative Content">We Need Creative Content</MenuItem>
                            <MenuItem value="We Need Motion Design">We Need Motion Design</MenuItem>
                            <MenuItem value="We're Ready A Mobile App">We're Ready A Mobile App</MenuItem>
                            <MenuItem value="We Need A Logo">We Need A Logo</MenuItem>
                            <MenuItem value="Marketing Event Coordinator">We Need Marketing Event Coordinator</MenuItem>
                            <MenuItem value="We Need A Website Content Manager">We Need A Website Manager</MenuItem>
                            <MenuItem value="We're Ready For A New Website">We're Ready For A New Website</MenuItem>
                            <MenuItem value="We Want A Web Store">We Want To Launch Our Web Store</MenuItem>
                            <MenuItem value="Social Media Manager">We'd Like A Freelance Social Media Manager</MenuItem>
                            <MenuItem value="Forward Thinking Marketing Strategy">We Need A Forward Thinking Marketing Strategist</MenuItem>
                            <MenuItem value="Connect Our Marketing Tools">We Have A Lot Of Great Tools But They Don't Connect</MenuItem>
                        </TextField>
                        <Button variant="contained" type="submit" sx={{ maxWidth: '250px', marginTop: '20px', backgroundColor: '#29B6F6' }}>
                            Submit
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Popup;
