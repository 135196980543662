import React, { useEffect, useState } from 'react';

const WebDevPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Shift + Alt + U is pressed
      if (event.shiftKey && event.altKey && event.code === 'KeyU') {
        event.preventDefault(); // Prevent default browser behavior
        setShowPopup(true); // Show the popup
      }
    };

    // Add event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <h2>Lightweight Web Development Service</h2>
            <p>
              Get a modern, user-friendly website designed to elevate your business.
              Our service includes sleek designs, seamless functionality, and mobile optimization.
            </p>
            <p>
              <strong>Price:</strong> $250 + taxes
            </p>
            <a href="https://buy.stripe.com/cN27td8XA4WxcrC6ow" target="_blank" rel="noopener noreferrer" style={styles.link}>
              Purchase Now
            </a>
            <button onClick={handleClosePopup} style={styles.closeButton}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: '#EEEEEE',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '90%',
  },
  link: {
    display: 'inline-block',
    marginTop: '10px',
    padding: '10px 20px',
    color: '#FFB300',
    textDecoration: 'none',
    borderRadius: '4px',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default WebDevPopup;
