import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const Stores = () => {
  const videoRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const element = videoRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (videoRef.current && inView) {
        const scale = .8 + currentPosition * 0.00025;
        videoRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView]);

  return (
    <Box
      sx={{
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#212121',
        color: '#ffffff',
        fontFamily: 'monospace',
        overflow: 'hidden',
        position: 'relative',
        padding: '40px'
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
          opacity: 0.1,
          transition: 'transform 0.2s ease-in-out'
        }}
      >
        <source src="/checkout-time.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box
        sx={{
          backgroundColor: '#212121',
          width: '100%',
          textAlign: 'left'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            paddingLeft: '40px',
            fontSize: '1.0rem',
            color: '#29B6F6!important',
            marginTop: '80px'
          }}
        >
          Your Very Own <Typography sx={{ color: '#FFB300', fontSize: '48px', lineHeight: '48px' }}>Custom Web Store</Typography>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            padding: { xs: '10px', sm: '20px', md: '30px', lg: '40px' },
            fontSize: '1.0rem',
            color: '#FFFFFF!important',
            marginTop: '20px'
          }}
        >
          "With a webstore, your shop is open 24/7, providing your customers the convenience of browsing and purchasing your products at any time. This round-the-clock availability means that you can capture sales beyond the typical business hours of a physical store, accommodating customers who prefer to shop late at night or early in the morning. As a result, you can significantly increase sales opportunities and reach a broader audience, ensuring that potential revenue is not missed due to time constraints."
        </Typography>
      </Box>
    </Box>
  );
};

export default Stores;
