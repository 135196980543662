import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const Dream = () => {
  const videoRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const element = videoRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (videoRef.current && inView) {
        const scale = 1 + currentPosition * 0.00025;
        videoRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView]);

  return (
    <Box
      sx={{
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#212121',
        color: '#ffffff',
        fontFamily: 'monospace',
        overflow: 'hidden',
        position: 'relative',
        padding: '40px'
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
          opacity: 0.1,
          transition: 'transform 0.2s ease-in-out'
        }}
      >
        <source src="/dream-business.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box
        sx={{
          backgroundColor: '#212121',
          width: '100%',
          textAlign: 'left'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            paddingLeft: '40px',
            fontSize: '1.0rem',
            color: '#29B6F6!important',
            marginTop: '80px'
          }}
        >
          The Only Limit Is <Typography sx={{ color: '#FFB300', fontSize: '48px', lineHeight: '48px' }}>Your Courage...</Typography>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            padding: { xs: '10px', sm: '20px', md: '30px', lg: '40px' },
            fontSize: '1.0rem',
            color: '#FFFFFF!important',
            marginTop: '20px'
          }}
        >
          "We are dreamers, artists, designers, programmers, and poets who believe in the power of our talents and creativity. Our commitment to turning dreams into reality sets us apart. At Lightweight, we combine the beauty of imagination with the precision of execution, bringing your visions to life in ways you've never imagined."
        </Typography>
      </Box>
    </Box>
  );
};

export default Dream;
