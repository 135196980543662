import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { db, auth } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const Leads = () => {
    const [open, setOpen] = useState(false);
    const [submissions, setSubmissions] = useState([]);
    const [user, setUser] = useState(null);

    // Listen for authenticated user
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    // Fetch submissions when dialog opens
    const fetchSubmissions = async () => {
        if (!user) return;

        try {
            const querySnapshot = await getDocs(collection(db, 'submissions'));
            const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setSubmissions(data);
        } catch (error) {
            console.error("Error fetching submissions:", error);
        }
    };

    // Handle keypress for Shift + K
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.shiftKey && event.key.toLowerCase() === 'k') {
                setOpen(true);
                fetchSubmissions();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [user]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { boxShadow: 24, borderRadius: 2, minWidth: '400px', margin: 'auto' } }}
            >
                <DialogTitle sx={{ position: 'relative', paddingRight: '48px' }}>
                    <Typography variant="h6">Submissions</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#FFB300',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {submissions.length > 0 ? (
                        <List>
                            {submissions.map((submission) => (
                                <ListItem key={submission.id} divider>
                                    <ListItemText
                                        primary={`Name: ${submission.fullName || 'N/A'}`}
                                        secondary={`Email: ${submission.email || 'N/A'} | Intent: ${submission.intent || 'N/A'} | Timestamp: ${submission.timestamp || 'N/A'}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography>No submissions found.</Typography>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Leads;