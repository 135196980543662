import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const Recovery = () => {
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.1, triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.2, triggerOnce: true });

  return (
    <Box sx={{ flexGrow: 1, marginTop: '80px', paddingBottom: '80px' }}>
      <Typography variant="h5" sx={{ fontSize: '1.5rem', color: '#29B6F6', marginTop: '80px', marginBottom: '40px' }}>
        Our Plan Is <Typography sx={{ fontFamily: 'Sacramento!important', color: '#FFB300', fontSize: '56px' }}>Simple</Typography>
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Card
            ref={ref1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: '#F5F5F5',
              transform: inView1 ? 'translateY(0)' : 'translateY(100px)',
              opacity: inView1 ? 1 : 0,
              transition: 'all 0.6s ease-out',
              marginBottom: '80px',
            }}
          >
            <CardMedia
              component="img"
              height="300"
              image="/martech.jpg" // Replace with your image path
              alt="Technologist work station"
            />
            <CardContent sx={{ padding: '40px', textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                Technology
              </Typography>
              <Typography variant="h6" component="h3" gutterBottom>
                Easy-To-Use Tools to Grow Your Business
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="&#8620; Data-Powered Websites" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="&#8620; Easy-To-Manage Webstores" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="&#8620; Custom Mobile Applications" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="&#8620; A.I. Powered Products" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="&#8620; CRM (Customer Relationship Management) Setup" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            ref={ref2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: '#F5F5F5',
              transform: inView2 ? 'translateY(0)' : 'translateY(100px)',
              opacity: inView2 ? 1 : 0,
              transition: 'all 0.6s ease-out',
              marginBottom: '48px',
              borderRadius: '8px',
            }}
          >
            <CardMedia
              component="img"
              height="300"
              image="/strategy.jpg" // Replace with your image path
              alt="Strategy"
            />
            <CardContent sx={{ padding: '40px', textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                Strategy
              </Typography>
              <Typography variant="h6" component="h3" gutterBottom>
                Let Us Help You Target Your Type Of Customer
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="&#8620; We Conduct Research - Competitor Analysis + Customer Psychographics" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="&#8620; You Run Your Business, We'll Manage Day-To-Day Marketing Activities like ad delivery schedules, budgeting, and more" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="&#8620; We Will Manage Your Social Media Accounts From Creative To Content Calendars" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Recovery;
