import React, { useEffect } from 'react';
import './FireballCursor.css';

const FireballCursor = () => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const cursor = document.querySelector('.fireball-cursor');
      const trails = document.querySelectorAll('.fireball-trail');

      cursor.style.left = `${event.clientX}px`;
      cursor.style.top = `${event.clientY}px`;

      trails.forEach((trail, index) => {
        setTimeout(() => {
          trail.style.left = `${event.clientX}px`;
          trail.style.top = `${event.clientY}px`;
        }, index * 40); // Adjust the delay for smooth trailing effect
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      <div className="fireball-cursor"></div>
      <div className="fireball-trail"></div>
      <div className="fireball-trail"></div>
      <div className="fireball-trail"></div>
      <div className="fireball-trail"></div>
      <div className="fireball-trail"></div>
    </>
  );
};

export default FireballCursor;
