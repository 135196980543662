import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import LanguageIcon from '@mui/icons-material/Language';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Helmet } from 'react-helmet';
import DashboardMarkup from './dashboardSchema'; // Make sure to import your DashboardMarkup component

const useIntersectionObserver = (options) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
	const observer = new IntersectionObserver(([entry]) => {
	  setIsVisible(entry.isIntersecting);
	}, options);

	if (ref.current) {
	  observer.observe(ref.current);
	}

	return () => {
	  if (ref.current) {
		observer.unobserve(ref.current);
	  }
	};
  }, [options]);

  return [ref, isVisible];
};

const Dashboard = () => {
  const [storeIconRef, storeIconVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [languageIconRef, languageIconVisible] = useIntersectionObserver({ threshold: 0.25 });
  const [socialIconRef, socialIconVisible] = useIntersectionObserver({ threshold: 0.4 });
  const [phoneIconRef, phoneIconVisible] = useIntersectionObserver({ threshold: 0.55 });

  return (
	<Box sx={{ flexGrow: 1, marginBottom: '80px' }}>
	  <Helmet>
		<title>Managed Marketing - Lightweight Agency</title>
		<meta name="description" content="Enhance all aspects of your online presence efficiently with Lightweight Agency's boutique managed marketing services." />
	  </Helmet>
	  <DashboardMarkup />
	  <Typography variant="h3" component="h1" gutterBottom sx={{ marginTop: '40px', fontSize: '2rem', fontFamily: 'Geist Mono, monospace!important' }}>
		We Are
		<Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Sacramento', color: '#FFB300', fontSize: '2.5rem', lineHeight: '3rem' }}>
		  ...here to resolve issues and save you money by handling &#8623;
		</Typography>
	  </Typography>
	  <Grid container spacing={4} sx={{ padding: 4 }}>
		{[
		  { id: 1, Icon: StoreIcon, title: 'Webstores', description: 'Transform your business with a custom-built online store. Reach a wider audience, increase sales, and manage inventory seamlessly.' },
		  { id: 2, Icon: LanguageIcon, title: 'Managed Websites', description: 'Focus on your strengths while we manage your website. Keep your site up-to-date, secure, and optimized for performance.' },
		  { id: 3, Icon: SocialDistanceIcon, title: 'Managed Social + Ads', description: 'Boost your brand with our social media and advertising management. Engage your audience, drive traffic, and generate leads.' },
		  { id: 4, Icon: PhoneAndroidIcon, title: 'Mobile App Development', description: 'Set your business apart with a mobile app. Showcase innovation, customer focus, and embrace new technologies.' },
		].map(({ id, Icon, title, description }) => (
		  <Grid item xs={12} sm={6} md={3} key={id} sx={{ display: 'flex' }}>
			<Paper
			  ref={id === 1 ? storeIconRef : id === 2 ? languageIconRef : id === 3 ? socialIconRef : phoneIconRef}
			  sx={{
				padding: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'space-between',
				width: '100%',
				transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
				transform: (() => {
				  switch (id) {
					case 1: return storeIconVisible ? 'translateY(0)' : 'translateY(50px)';
					case 2: return languageIconVisible ? 'translateY(0)' : 'translateY(50px)';
					case 3: return socialIconVisible ? 'translateY(0)' : 'translateY(50px)';
					case 4: return phoneIconVisible ? 'translateY(0)' : 'translateY(50px)';
					default: return 'translateY(0)';
				  }
				})(),
				opacity: (() => {
				  switch (id) {
					case 1: return storeIconVisible ? 1 : 0;
					case 2: return languageIconVisible ? 1 : 0;
					case 3: return socialIconVisible ? 1 : 0;
					case 4: return phoneIconVisible ? 1 : 0;
					default: return 1;
				  }
				})(),
				boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
				'&:hover': {
				  boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
				},
				height: '100%',
			  }}
			>
			  <Icon sx={{ fontSize: '3rem', marginBottom: 2 }} />
			  <Typography variant="h5" sx={{ fontSize: '1.5rem', textAlign: 'center' }}>{title}</Typography>
			  <Typography variant="body1" sx={{ textAlign: 'center' }}>{description}</Typography>
			</Paper>
		  </Grid>
		))}
	  </Grid>
	</Box>
  );
};

export default Dashboard;
