import React, { useEffect } from 'react';

const Portfolio = () => {
  // Path to the PDF file
  const pdfUrl = '/portfolio-december-24.pdf'; // Replace with your PDF file's URL

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Shift + Alt + P is pressed
      if (event.shiftKey && event.altKey && event.code === 'KeyH') {
        event.preventDefault(); // Prevent default browser behavior
        // Create an anchor element to download the PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = '/lightweight-mini-portfolio.pdf'; // Set the download filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    // Add event listener to the document
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [pdfUrl]);

  return (
    <div>
    </div>
  );
};

export default Portfolio;
