import React from 'react';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';

const PricingComponent = () => {
  const pricingPlans = [
	{
	  id: 1,
	  title: 'Most Popular - Social Media Campaign Managemnt',
	  price: '$100/month',
	  description: 'Perfect for small businesses starting out on social media.',
	  features: ['- 30 High-Quality Posts', '- Audience Growth Strategies', '- Analytics Insights'],
	},
	{
	  id: 2,
	  title: 'Online Presence Management',
	  price: '$250/one-time, $24-50 monthly',
	  description: 'Ideal for growing businesses that need a stronger presence.',
	  features: ['- New Website, Webstore, or Web App', '- SEO and SEM', '- Hosting and DNS management', '- Content Managemnt'],
	},
	{
	  id: 3,
	  title: 'Marketing Campaign Management',
	  price: '$400/one-time, $100-500 monthly',
	  description: 'Best for businesses aiming for maximum reach and sales.',
	  features: ['- Campaign Briefs', ' - Bi-Weekly Online Strategy Updates', ' - New Website, Webstore, or Web App', '- Competitor and Customer Marketing Research', '- Muli-Channel Marketing', '- SEO and SEM', '- Paid Advertising Budget and Strategy'],
	},
  ];

  return (
	<Box sx={{ flexGrow: 1, p: 4 }}>
	  <Typography variant="h5" sx={{ fontSize: '1.5rem', color: '#29B6F6', marginTop: '80px', marginBottom: '40px' }}>
		Our Most <Typography sx={{ fontFamily: 'Sacramento!important', color: '#FFB300', fontSize: '56px' }}>Popular Plans</Typography>
	  </Typography>
	  <Grid container spacing={4} justifyContent="center">
		{pricingPlans.map((plan) => (
		  <Grid item xs={12} sm={6} md={4} key={plan.id}>
			<Paper
			  sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				minHeight: '400px',
				padding: 3,
				position: 'relative',
			  }}
			>
			  <Box>
				<Typography variant="h5" sx={{textAlign: 'left'}} gutterBottom>
				  {plan.title}
				</Typography>
				<Typography variant="h6" color="#FFB300" sx={{textAlign: 'left'}} gutterBottom>
				  {plan.price}
				</Typography>
				<Typography variant="body1" color="#212121" sx={{textAlign: 'left'}} gutterBottom>
				  {plan.description}
				</Typography>
				<Typography variant="body1" color="#212121" sx={{ textAlign: 'left' }} gutterBottom>
				{Array.isArray(plan.features)
					? plan.features.map((feature, index) => (
						<div key={index}>
						{feature}
						</div>
					))
					: plan.features}
				</Typography>
			  </Box>
			</Paper>
		  </Grid>
		))}
	  </Grid>
	</Box>
  );
};

export default PricingComponent;
