import React, { useState, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword } from "firebase/auth";
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PopupLogin = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  // Define hotkey behavior
  useHotkeys('shift+l', handleOpen);

  return (
    <>
      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { boxShadow: 24, borderRadius: 2, minWidth: '300px', margin: 'auto' } }}>
        <DialogTitle>
          <Typography className='brand-font primary'>Log In</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" onSubmit={handleLogin} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" variant="contained" color="primary">
              Log In
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopupLogin;
