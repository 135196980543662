// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrvihtthe0yHDqVKydgj_NLs5NF6a8x7I",
  authDomain: "lightweight-agency.firebaseapp.com",
  projectId: "lightweight-agency",
  storageBucket: "lightweight-agency.firebasestorage.app",
  messagingSenderId: "314387794784",
  appId: "1:314387794784:web:7d8066acb0c9fba1532c05",
  measurementId: "G-8Q1E0RVE49"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics
const analytics = getAnalytics();
// Initialize Firestore
const db = getFirestore(app);
// Initialize Messaging
const auth = getAuth(app);

export { db, auth };