import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

const Hero = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const translateX = scrollPosition * 0.1; // Control the horizontal parallax effect
    const translateY = scrollPosition * 0.05; // Control the vertical parallax effect
    const rotate = scrollPosition * 0.02; // Control the rotation effect

    return (
        <Box
            sx={{
                maxHeight: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '60px',
                backgroundColor: '#f5f5f5',
                backgroundImage: 'url("/hero-bg.png")',
                backgroundRepeat: 'repeat',
                overflow: 'hidden',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Typography variant="h3" component="h1" gutterBottom>
                            Welcome to <Typography sx={{ fontFamily: "Sacramento!important", color: "#FFB300", fontSize: "56px" }}>lightweight</Typography>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Launch your website in as little as 3 days. Plus, we offer advanced strategy to put it to good use. No more pretty websites that do nothing for your business.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            transform: `translate(${translateX}px, ${translateY}px) rotate(${rotate}deg)`,
                            transition: 'transform 0.1s ease-out',
                            overflow: 'hidden'
                        }}
                    >
                        <img
                            src="/phone.png"
                            alt="vector phone with lightweight's icon on the home screen"
                            style={{ maxWidth: '100%', maxHeight: '600px' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Hero;
