import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const AndroidCodeBackground = () => {
  const videoRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const element = videoRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (videoRef.current && inView) {
        const scale = .8 + currentPosition * 0.00035;
        videoRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView]);

  return (
    <Box
      sx={{
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#212121',
        color: '#ffffff',
        fontFamily: 'monospace',
        overflow: 'hidden',
        position: 'relative',
        padding: '40px',
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
          opacity: 0.2,
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <source src="/dev-computer.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box
        sx={{
          backgroundColor: '#212121',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            paddingLeft: '40px',
            fontSize: '1.0rem',
            color: '#29B6F6!important',
            marginTop: '48px',
          }}
        >
          Imagine Your Next <Typography sx={{ color: '#FFB300', fontSize: '48px!important', lineHeight: '48px' }}>Mobile App</Typography>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            padding: { xs: '10px', sm: '20px', md: '30px', lg: '40px' },
            fontSize: '1.0rem',
            color: '#FFFFFF',
            marginTop: '20px',
          }}
        >
          In today's fast-paced digital world, having a mobile app is no longer a luxury—it's a necessity. Our mobile app development services are
          designed to help small businesses like yours thrive in today's hyper-competitive market. By offering seamless user experiences, enhanced
          accessibility, and real-time engagement, our mobile apps empower you to connect with customers more effectively and drive growth.
        </Typography>
      </Box>
    </Box>
  );
};

export default AndroidCodeBackground;
