import React from 'react';
import { Helmet } from 'react-helmet';

const DashboardMarkup = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Lightweight Agency",
    "url": "https://www.lightweight.agency",
    "logo": "https://www.lightweight.agency/logo.png",
    "description": "We Are here to resolve issues and save you money by handling all your web development, social media management, and mobile app needs.",
    "sameAs": [
      "https://www.facebook.com/lightweight.agency",
      "https://www.instagram.com/lightweight.agency"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-682-221-7208",
      "contactType": "Customer Service"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default DashboardMarkup;
